/* GitHub Repos Section Container */
#github-repos {
  max-width: 100%;
  padding: 10px 0px;
  overflow: hidden;
}

/* Repo Individual Item */
#github-repos .repo {
  border: 1px solid #e0e0e0; /* Lighter border for subtlety */
  border-radius: 8px; /* Rounded corners */
  margin-bottom: 20px; /* Increased spacing */
  padding: 20px; /* More internal space */
  background-color: #fff; /* Background color for contrast */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out; /* Smooth transition for hover effects */
}

#github-repos .repo:hover {
  transform: translateY(-5px); /* Slight lift effect */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Enhanced shadow on hover */
}

/* Repo Title */
/* Enhanced Repo Title */
#github-repos .repo h2 {
  margin: 0 0 10px 0; /* Maintain bottom margin for spacing */
  font-size: 1.6rem; /* Slightly larger for emphasis */
  color: #2a6496; /* A distinct color different from standard link colors */
  font-weight: 700; /* Bolder weight for emphasis */
  transition: color 0.3s ease-in-out; /* Smooth transition for color change */
}

#github-repos .repo h2 a {
  text-decoration: none; /* Removes underline from links */
  color: inherit; /* Inherits color from h2 for consistency */
}

#github-repos .repo h2 a:hover {
  color: #204d74; /* Darker shade on hover for feedback */
  text-decoration: underline; /* Adds an underline on hover for a traditional link feel */
}

/* Repo Description */
#github-repos .repo p {
  font-size: 1rem; /* More readable size */
  color: #666; /* Softened color */
  margin-bottom: 12px; /* Added space below paragraph */
}

/* Repo Meta Data (e.g., stars, forks) */
#github-repos .repo span {
  display: inline-flex; /* Align items center */
  align-items: center;
  margin-right: 15px; /* Increased spacing */
  font-size: 0.9rem; /* Adjusted for consistency */
  color: #777; /* Darker for readability */
  background-color: #f9f9f9; /* Subtle background */
  padding: 5px 8px; /* Padding for better touch */
  border-radius: 4px; /* Rounded corners for tags */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05); /* Soft shadow for depth */
}

/* Enhancing visibility and separation of metadata */
#github-repos .repo span:not(:last-child) {
  margin-bottom: 8px; /* Space between lines if wrapped */
}

/* New CSS for the repo link */
.repo-link {
  text-decoration: none; /* Removes underline from the link */
  color: inherit; /* Inherits the text color from parent elements */
  display: block; /* Makes the link block-level to contain the div */
  margin-bottom: 20px; /* Ensures spacing between items */
}

/* Ensuring the repo div is styled correctly on hover */
.repo-link:hover .repo, .repo-link:focus .repo {
  transform: translateY(-5px); /* Lift effect on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Enhanced shadow on hover */
}

